<!--
 * @Author: 墨凡 oncwnuN3VU3V55c1lqO_6llf5DtU@git.weixin.qq.com
 * @Date: 2022-07-02 09:22:42
 * @LastEditors: zll zll1217168026@163.com
 * @LastEditTime: 2022-09-28 11:17:46
 * @FilePath: \ding-lims\src\views\qrcode.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="qrcode">
    <van-nav-bar
      title="扫描检测"
      left-text="返回"
      left-arrow
      @click-left="back"
    />
    <div class="scan">
      <p style="margin-left:14px">请使用扫描枪或者直接输入报检ID</p>
      <van-search v-model="code" 
        left-icon="" 
        shape="round"
        background="#3d7cf3"
        show-action 
        autofocus 
        placeholder="使用扫描枪请确认光标在此···">
        <template #action>
          <div style="color:#fff" @click="toUrl">确定</div>
        </template>
      </van-search>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import { Search } from 'vant'
import * as dd from 'dingtalk-jsapi'
Vue.use(Search)

export default {
  data() {
    return {
      code: ''
    }
  },
  methods: {
    toUrl() {
      if (this.CHECK_URL(this.code)) {
        if (dd.env.platform !== 'notInDingTalk') {
          const url = this.code.split('#/')[1]
          this.$router.push({path: url})
        } else {
          window.open(this.code)
        }
      } else if (!isNaN(Number(this.code, 10))) {
        this.$router.push({path: '/AnalysisTb', query: {id: this.code}})
      } else {
        this.$notify({ type: 'warning', message: '您这不是网址也不是id呀！' })
      }
    },
    CHECK_URL(url) {
		//url= 协议://(ftp的登录信息)[IP|域名](:端口号)(/或?请求参数)
		const strRegex = '^((https|http|ftp)://)?'//(https或http或ftp):// 可有可无
			+ '(([\\w_!~*\'()\\.&=+$%-]+: )?[\\w_!~*\'()\\.&=+$%-]+@)?' //ftp的user@  可有可无
			+ '(([0-9]{1,3}\\.){3}[0-9]{1,3}' // IP形式的URL- 3位数字.3位数字.3位数字.3位数字
			+ '|' // 允许IP和DOMAIN（域名） 
			+ '(localhost)|'	//匹配localhost
			+ '([\\w_!~*\'()-]+\\.)*' // 域名- 至少一个[英文或数字_!~*\'()-]加上.
			+ '\\w+\\.' // 一级域名 -英文或数字  加上.
			+ '[a-zA-Z]{1,6})' // 顶级域名- 1-6位英文 
			+ '(:[0-9]{1,5})?' // 端口- :80 ,1-5位数字
			+ '((/?)|' // url无参数结尾 - 斜杆或这没有
			+ '(/[\\w_!~*\'()\\.;?:@&=+$,%#-]+)+/?)$'// 请求参数结尾- 英文或数字和[]内的各种字符
		const re=new RegExp(strRegex, 'i') // i不区分大小写
		// 将url做uri转码后再匹配，解除请求参数中的中文和空字符影响
		if (re.test(encodeURI(url))) {
			return true
		} else {
			return false
		}
  },
  back() {
    this.$router.push({ path: '/home' })
    // this.$router.go(-1)
  },
  }
}
</script>
<style scoped>
.scan{
  position: relative;
  top: -30px;
  width: 100%;
  margin: 40% 0 auto;
}
</style>
